import {
  Box,
  Button,
  Image,
  Text,
  Heading,
  VStack,
  Link,
  HStack
} from '@chakra-ui/react';
import { Link as GatsbyLink } from "gatsby";
import { motion } from 'framer-motion';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import React, { useState, useContext } from 'react';
import Layout from '../components/Layout';
import Wrapper from '../components/atoms/Wrapper';
import ClientOnly from '../components/atoms/ClientOnly';
import { UserContext } from '../context/user';
import badgeSrc from '../assets/badge-locked.png';
import badgeCollectedSrc from '../assets/badge-collected.png';
import boxSrc from '../assets/box-welcome.png';
import logoSrc from '../assets/logo.svg';
import step1Src from '../assets/step1.png';
import step2Src from '../assets/step2.png';
import step3Src from '../assets/step3.png';
import borderSrc from '../assets/border.png';
import jetSetterSrc from '../assets/jet-setter.png';
import goingGalacticSrc from '../assets/going-galactic.png';
import lockOpenSrc from '../assets/lock-open.svg';
import lockClosedSrc from '../assets/lock-closed.svg';

const isBrowser = typeof window !== "undefined";

const IndexPage = ({ location }) => {
  const closeWelcome = () => {
    if (isBrowser) {
      window.localStorage.setItem('welcome', 1);
    }
    setShowWelcome(false);
  };

  const [showWelcome, setShowWelcome] = useState(
    isBrowser ? !window.localStorage.getItem('welcome') : false
  );

  const badges = [1, 2, 3];

  const { userBadges, addBadge } = useContext(UserContext);

  const badgeFromURL =
    parseInt(new URLSearchParams(location.search).get('collect')) || false;
  const newBadge = badgeFromURL ? badges.find(badge => badge === badgeFromURL) : false;
    
  if (newBadge) {
    addBadge(newBadge);
  }

  const badgesRequired = 2;
  const badgesLeft = badgesRequired - userBadges.length;
  const isLocked = userBadges.length < badgesRequired;

  return (
    <Layout location={location}>
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: "spring",
              mass: 0.35,
              stiffness: 75,
              duration: 0.3
            }}
          >
        <Box position="relative" zIndex="1">
            <Box>
              <ClientOnly>
                <Wrapper>
                  {userBadges.length < badgesRequired ?
                    <Box textAlign="center" px="4">
                      <Heading size="md" mb="3">
                        {badgesLeft} More QR Code{badgesLeft > 1 && "s"} To Find
                      </Heading>
                      <Text fontSize="sm" textTransform="uppercase">
                        Open Camera<br/>
                        Find an adidas ZX22 QR Code in store<br/>
                        Scan to unlock
                      </Text>
                    </Box>
                  :
                    <Box textAlign="center" px="4">
                      <Heading size="md" mb="3">
                        Now boarding
                      </Heading>
                      <Text fontSize="sm" textTransform="uppercase" mb="3">
                        Secure your ZX swag before departure With adidas purchases over £50
                      </Text>
                      <Text fontSize="sm" textTransform="uppercase">
                        Show your Boarding Pass at the till
                      </Text>
                    </Box>
                  }
                  <Image src={userBadges.length <= 1 ? jetSetterSrc : goingGalacticSrc} width="18rem" mx="auto" my="4"></Image>
                </Wrapper>
                <Box w="100%" mb="8" sx={{ 
                  '.swiper': {
                    pb: '2rem'
                  },
                  '.swiper-pagination-bullet': { 
                      background: 'green.100',
                      width: '1.25rem',
                      height: '.1875rem',
                      borderRadius: 'none',
                      opacity: 1,
                      transition: '.3s ease-in-out'
                  },
                  '.swiper-pagination-bullet-active': {
                      background: 'green.500',
                      width: '2.75rem'
                  }
                }}>
                  <Swiper
                    modules={[Pagination]}
                    slidesPerView={'auto'}
                    centeredSlides={true}
                    spaceBetween={0}
                    loop={true}
                    pagination={{ clickable: true }}
                  >
                    {[...Array(2).keys()].map(num => (
                      <SwiperSlide
                        key={num}
                        style={{ width: '20rem', height: '12.625rem' }}
                      >
                        <Box
                          w="100%"
                          h="100%"
                          p="3.25rem 1.85rem"
                          bg="black"
                          color="white"
                        >
                          <Image
                            src={
                              num >= userBadges.length
                                ? badgeSrc
                                : badgeCollectedSrc
                            }
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                          />
                        </Box>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Box>
                <Wrapper>
                  <VStack spacing="4">
                    <Button as={!isLocked ? GatsbyLink : null} to="/share" variant="primary" w="100%" isDisabled={isLocked}>
                      Unlock my Boarding Pass <Image mt="-.1rem" ml="3" src={isLocked ? lockClosedSrc : lockOpenSrc}/>
                    </Button>
                    <Link
                      as="button"
                      variant="underline"
                      onClick={() => setShowWelcome(true)}
                    >
                      Instructions
                    </Link>
                  </VStack>
                </Wrapper>
              </ClientOnly>
            </Box>
          
        </Box>
        {showWelcome &&
          <Box position="relative" zIndex="2">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                type: "spring",
                mass: 0.35,
                stiffness: 75,
                duration: 0.1
              }}
            >
              <Box position="fixed" top="0" left="0" w="100%" h="100%" py="6" zIndex="999999" bg="rgba(0,0,0,.6)" overflowY="auto">
                <Wrapper>
                  <Box position="relative" width="108%" ml="-4%" mb="6">
                    <Image width="100%" src={boxSrc} alt="Box"/>
                    <Box position="absolute" top="0" left="0" width="100%" height="100%" px="10" py="14">
                      <Image src={logoSrc} alt="adidas JDZX SS22" width="auto" mx="auto" mb="4"/>
                      <Box textAlign="center">
                        <Heading size="md">
                          You’ve found your<br/> first ZX QR code
                        </Heading>
                      </Box>
                      <Image width="12rem" mx="auto" mt="2" src={userBadges.length <= 1 ? jetSetterSrc : goingGalacticSrc}/>
                      <VStack spacing="1" mb="4">
                        <HStack width="100%" spacing="4">
                          <Image width="4.25rem" ml="-.5rem" src={step1Src} alt="Step 1"/>
                          <Text fontSize="xs" textTransform="uppercase">Find &amp; scan a second QR code near adidas ZX22 areas</Text>
                        </HStack>
                        <Image src={borderSrc} alt="Border"/>
                        <HStack width="100%" spacing="4">
                          <Image width="4.25rem" ml="-.5rem" src={step2Src} alt="Step 2"/>
                          <Text fontSize="xs" textTransform="uppercase">Unlock your VIP Boarding Pass</Text>
                        </HStack>
                        <Image src={borderSrc} alt="Border"/>
                        <HStack width="100%" spacing="4">
                          <Image width="4.25rem" ml="-.5rem" src={step3Src} alt="Step 3"/>
                          <Text fontSize="xs" textTransform="uppercase">Collect your ZX gift with <br/>in-store adidas purchases over £50</Text>
                        </HStack>
                      </VStack>
                      <Box textAlign="center">
                        <Button variant="primary" width="100%" mb="4" onClick={closeWelcome}>
                          Close
                        </Button> 
                        <Link
                          as={GatsbyLink}
                          to="/terms-conditions"
                          variant="underline"
                          fontSize="sm"
                          fontWeight="normal"
                        >
                          Terms & Conditions
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Wrapper>
              </Box>
            </motion.div>
          </Box>
        }
      </motion.div>
    </Layout>
  );
}

export default IndexPage
